import React from "react";
import classNames from "classnames";
import { Container, Text } from "@atoms";

const LocationGrid = ({
  className: _className,
  locations,
  paddingBottom,
  paddingTop,
}) => {
  return (
    <section
      className={classNames(
        {
          "pt-20 md:pt-28": paddingTop,
          "pb-20 md:pb-28": paddingBottom,
        },
        _className
      )}
    >
      <Container>
        <div className="grid grid-cols-1 gap-8 md:grid-cols-2 lg:grid-cols-3">
          {locations.map(loc => {
            const { heading, descriptor, phone } = loc;
            return (
              <div className="flex flex-col space-y-4">
                <Text className="text-lg font-bold">{heading}</Text>
                <Text className="text-lg leading-tight xl:text-base">
                  {descriptor}
                </Text>
                <Text className="text-lg font-bold xl:text-base">{phone}</Text>
              </div>
            );
          })}
        </div>
      </Container>
    </section>
  );
};

LocationGrid.defaultProps = {};

export default LocationGrid;
